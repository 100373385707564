export const sendEmail = async ({ toAddress, ccAddress, replyTo, subject, body, bcc, cc }) => {
    const apiUrl = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_email';
    const apiKey = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz';
  
    // Set default bcc if not provided
   
  
    const payload = {
        toAddress,
        subject,
        body,  
      };
  
    if (cc) {
      payload.cc = cc;
    }
  
    if (bcc) {
      payload.bcc = bcc;
    }
  
    if (replyTo) {
      payload.replyTo = replyTo;
    }
  
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': apiKey,
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error sending email: ${response.status} ${response.statusText} - ${errorText}`);
      }
  
      console.log('Email sent successfully');
    } catch (error) {
      console.error('Error sending email:', error);
      throw error;
    }
  }