import React, { useContext, useState, useEffect } from "react";
import { Navbar, Nav, Form, FormCheck } from "react-bootstrap";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";
import collinslogo from "./img/collinslogo.png";
import "./css/components.css";
import { useMsal } from "@azure/msal-react";
import { slide as Menu } from 'react-burger-menu';
import { EncodeDecodeContextProvider } from "./context/EncodeDecodeContext";
import MobileFooter from "./MobileFooter";
import UserContext from './context/UserContext';
import { ThemeContext } from './context/ThemeContext'; // Import ThemeContext

/**
 * Renders the navbar component with a sign-in button if a user is not authenticated
 */
export const PageLayout = (props) => {
    const { user } = useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    const name = accounts[0] && accounts[0].name;
    const currentYear = new Date().getFullYear();

    return (
        <EncodeDecodeContextProvider>
            <div className="app">
                <div className="content">
                    <Navbar bg="dark" variant="dark">
                        <Navbar.Brand className="navbar-logo-spacing">
                            <img className="navbar__brand" src={collinslogo} alt="Collins Logo" />
                            <a className="navbar-brand" href="/">CONEXCS</a>
                        </Navbar.Brand>
                        <Nav className="ml-auto navbar-signin-spacing d-flex align-items-center">
                            <Nav.Item className="me-3">
                                {isAuthenticated ? <SignOutButton /> : <SignInButton />}
                            </Nav.Item>
                        </Nav> 
                    </Navbar>   
                    <div>
                        {props.children}
                    </div>
                </div>
                <div className='footer'>
                    <p className='footer-text'>
                        © {currentYear} Collins Commercial Services, all rights reserved.
                    </p>
                </div>
            </div>
            {user !== null && <MobileFooter />}
        </EncodeDecodeContextProvider>
    );
};