import React, { useState, useContext } from 'react';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import UserContext from './context/UserContext';

const MobileFooter = () => {
    const { user } = useContext(UserContext);

    const [show, setShow] = useState(false);

    const handleToggle = () => setShow(!show);

    const hasSpecialAccess = false;

    return (
        <footer className="mobile-footer d-md-none">
            <Navbar bg="dark" variant="dark" expand="lg" fixed="bottom">
                <Container>
                    <div></div>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle} />
                    <Navbar.Collapse id="basic-navbar-nav" className={show ? "show" : ""}>
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to="/">Home</Nav.Link>
                            <NavDropdown title="Techroom" id="basic-nav-dropdown">
                                <NavDropdown.Item as={Link} to="/CalendarOnCall">On Call Calendar</NavDropdown.Item>
                                <NavDropdown.Item href="https://app.smartsheet.com/b/form/5a919dee44034735a03e9f9c70fcfa33" target="_blank">Request PTO</NavDropdown.Item>
                                <NavDropdown.Item href="https://www.appsheet.com/start/1e3f4aef-38f8-43d6-becb-70245f81036b" target="_blank">Phone List</NavDropdown.Item>
                                <NavDropdown.Item href="https://app.smartsheet.com/b/form/e7861e6197194093ad2312ef81b317ae" target="_blank">Water Heater</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/Training">Training</NavDropdown.Item>
                                {(user.role_id >= 1) && (
                                    <NavDropdown.Item as={Link} to="/AppointmentWorkFlow">Appointments</NavDropdown.Item>
                                )}
                            </NavDropdown>

                            {user.role_id === 9 && (
                                <>
                                    <Nav.Link as={Link} to="/ITSupport">IT Support</Nav.Link>
                                    <Nav.Link as={Link} to="/ITAdmin">IT Admin</Nav.Link>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </footer>
    );
};

export default MobileFooter;