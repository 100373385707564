export const msalConfig = {
  auth: {
    clientId: "e398b977-0bfe-4200-9ccd-aaab626b15ec",
    authority:'https://login.microsoftonline.com/sundevilplumbing.onmicrosoft.com',
    redirectUri: "https://www.conexcs.com/"//"http://localhost:3000" //"https://www.conexcs.com/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
 scopes: ["User.Read"]
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
    graphMe: "https://graph.microsoft.com/v1.0/"
};