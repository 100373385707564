// src/components/WorkOrdersTable.js

import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Spinner, Alert } from 'react-bootstrap';

const WorkOrdersTable = () => {
  const [workOrders, setWorkOrders] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'job_date', direction: 'desc' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Replace with your actual API Gateway endpoint
  const API_ENDPOINT = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_target_orders';

  useEffect(() => {
    const fetchWorkOrders = async () => {
      try {
        const response = await fetch(API_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz'
          },
          body: JSON.stringify({})
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setWorkOrders(data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching work orders:', err);
        setError(true);
        setLoading(false);
      }
    };

    fetchWorkOrders();
  }, [API_ENDPOINT]);

  const sortedWorkOrders = React.useMemo(() => {
    let sortableItems = [...workOrders];
    if (sortConfig.key) {
      sortableItems.sort((a, b) => {
        const valA = a[sortConfig.key];
        const valB = b[sortConfig.key];

        // Handle null or undefined values
        if (valA === null || valA === undefined) return 1;
        if (valB === null || valB === undefined) return -1;

        // Sorting logic based on key
        if (sortConfig.key === 'job_date') {
          return new Date(valA) - new Date(valB);
        }

        // For strings
        if (typeof valA === 'string' && typeof valB === 'string') {
          return valA.localeCompare(valB);
        }

        // Default comparison
        if (valA < valB) return -1;
        if (valA > valB) return 1;
        return 0;
      });

      if (sortConfig.direction === 'desc') {
        sortableItems.reverse();
      }
    }
    return sortableItems;
  }, [workOrders, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getHeaderClassName = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? 'sort-asc' : 'sort-desc';
    }
    return '';
  };

  const renderSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'asc' ? ' 🔼' : ' 🔽';
    }
    return '';
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center mt-5">
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading work orders...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="mt-5">
        Failed to load work orders. Please try again later.
      </Alert>
    );
  }

  return (
    <div className="container mt-4">
      <h2>Target Work Order Management</h2>
      <Table striped bordered hover responsive className="mt-3">
        <thead className="thead-dark">
          <tr>
            <th onClick={() => requestSort('store_number')} style={{ cursor: 'pointer' }}>
              Store Number {renderSortIcon('store_number')}
            </th>
            <th onClick={() => requestSort('job_date')} style={{ cursor: 'pointer' }}>
              Job Date {renderSortIcon('job_date')}
            </th>
            <th onClick={() => requestSort('issue_description')} style={{ cursor: 'pointer' }}>
              Issue Description {renderSortIcon('issue_description')}
            </th>
            <th onClick={() => requestSort('city_state')} style={{ cursor: 'pointer' }}>
              City, State {renderSortIcon('city_state')}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedWorkOrders.map((wo) => (
            <tr key={wo.work_order_number}>
              <td>{wo.store_number}</td>
              <td>{new Date(wo.job_date).toLocaleString()}</td>
              <td>{wo.issue_description}</td>
              <td>{wo.city_state}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default WorkOrdersTable;
