// src/components/AppointmentScreen.jsx

import './css/appointments.css';

import React, { useState, useEffect, useRef, useContext } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import { fetchAndMatchTechnicians } from '../utils/fetchTechnicians';
import { fetchAppointments } from '../utils/fetchAppointments';
import { cancelAppointment } from '../utils/cancelAppointment';
import { updateAppointmentStatus } from '../utils/updateAppointmentStatus';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Confetti from 'react-confetti'; // Import Confetti
import UserContext from './context/UserContext'; // Use UserContext instead of AuthContext
import { fetchServiceOrderDetails } from '../utils/fetchServiceOrderDetails'; // Import the new function
import { Form } from 'react-bootstrap'; // Add Form here
import { sendEmail } from '../utils/email';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FaStickyNote } from 'react-icons/fa'; // Import the notes icon

export default function AppointmentScreen() {
  const { serviceOrderNumber } = useParams();
  const location = useLocation();
  const [serviceOrderDetails, setServiceOrderDetails] = useState(
    location.state?.serviceOrderDetails || {}
  );

  const [appointments, setAppointments] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appointmentsLoading, setAppointmentsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [appointmentToCancel, setAppointmentToCancel] = useState(null);
  const [technicianSearchTerm, setTechnicianSearchTerm] = useState('');
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [selectedAppointmentDate, setSelectedAppointmentDate] = useState(() => {
    const today = new Date();
    return today.toISOString().split('T')[0]; // Format as YYYY-MM-DD
  });
  const [modalStep, setModalStep] = useState(1); // Step 1: Select Technician, Step 2: Select Date
  const [showConfetti, setShowConfetti] = useState(false); // State to control confetti display
  const [techNumber, setTechNumber] = useState(1); // New state for Tech number

  const modalRef = useRef(null); // Create a ref for the modal
  const [modalDimensions, setModalDimensions] = useState({ width: 0, height: 0 });

  const { user } = useContext(UserContext); // Get current user from UserContext

  // State for hold functionality
  const [holdLoading, setHoldLoading] = useState(false);
  const [holdError, setHoldError] = useState(null);

  // State for technician change modal
  const [showTechChangeModal, setShowTechChangeModal] = useState(false);
  const [appointmentToChangeTech, setAppointmentToChangeTech] = useState(null);

  // State for appointment date change modal
  const [showDateChangeModal, setShowDateChangeModal] = useState(false);
  const [appointmentToChangeDate, setAppointmentToChangeDate] = useState(null);
  const [newAppointmentDate, setNewAppointmentDate] = useState(null);

  // State for dispatch notes modal
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [appointmentForNotes, setAppointmentForNotes] = useState(null);
  const [dispatchNotes, setDispatchNotes] = useState('');

  // **Configuration: API Key and Endpoint URLs**
  const API_KEY = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz'; // Shared API Key for endpoints
  const CREATE_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_visit'; // Replace with your actual endpoint URL
  const CREATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_insert_appointment'; // Updated endpoint
  const UPDATE_TARGET_VISIT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';
  const UPDATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment_details';

  // Add state to store the appointment duration
  const [appointmentDuration, setAppointmentDuration] = useState(1);

  // Add these state variables at the top of your component
  const [showDurationModal, setShowDurationModal] = useState(false);
  const [selectedAppointmentForDuration, setSelectedAppointmentForDuration] = useState(null);
  const [newDuration, setNewDuration] = useState(1);

  useEffect(() => {
    if (modalRef.current) {
      setModalDimensions({
        width: modalRef.current.offsetWidth,
        height: modalRef.current.offsetHeight,
      });
    }
  }, [showModal, showTechChangeModal, modalStep]); // Update dimensions when modal opens or step changes

  // Fetch appointments for the serviceOrderNumber
  useEffect(() => {
    const getAppointments = async () => {
      setAppointmentsLoading(true);
      setError(null);

      try {
        const fetchedAppointments = await fetchAppointments(serviceOrderNumber);
        setAppointments(
          fetchedAppointments.map((appointment) => ({
            ...appointment,
            AppointmentDate: appointment.AppointmentDate || 'N/A', // Ensure AppointmentDate is set
          }))
        );
        setAppointmentsLoading(false);
      } catch (err) {
        console.error('Error fetching appointments:', err);
        setError('Failed to load appointments. Please try again later.');
        setAppointmentsLoading(false);
      }
    };

    getAppointments();
  }, [serviceOrderNumber]);

  /**
   * Handles the action of adding a new appointment.
   * Fetches available technicians and displays the selection modal.
   */
  const handleAddAppointment = () => {
    setModalStep(1); // Reset to the first step when starting a new appointment
    setShowModal(true);
  };

  /**
   * Sets the selected technician based on user selection.
   * @param {Object} technician - The technician object selected by the user.
   */
  const handleSelectTechnician = (technician) => {
    setSelectedTechnician(technician);
  };

  /**
   * Extracts the location ID from the customer name.
   * Assumes the location ID is the last 4 digits.
   * @returns {string|null} - The extracted location ID or null if not found.
   */
  const extractLocationId = () => {
    const customerName = serviceOrderDetails?.customerName || '';
    const locationIdMatch = customerName.match(/T(\d{4})$/);
    if (locationIdMatch && locationIdMatch[1]) {
      return locationIdMatch[1];
    }
    // Fallback: last 4 characters
    const lastFour = customerName.slice(-4);
    return /^\d{4}$/.test(lastFour) ? lastFour : null;
  };

  /**
   * Calculates the estimated time of arrival, which is always one hour from the current time.
   * @returns {string} - The estimated time of arrival in ISO format.
   */
  const getEstimatedTimeArrival = () => {
    const now = new Date();
    const estimatedTime = new Date(now.getTime() + 60 * 60 * 1000); // Add 1 hour
    return estimatedTime.toISOString();
  };

  /**
   * Fetches Visit ID from the Create Visit API and returns it.
   * Adds logging for the request and response.
   * @returns {string|null} - The fetched VisitID or null if not applicable.
   */
  const fetchVisitID = async () => {
    // Check if customer name includes "Target" and customer order is valid
    const customerName = serviceOrderDetails?.customerName || '';
    const customerOrder = serviceOrderDetails?.customerOrder || '';

    const isTargetCustomer = customerName.toLowerCase().includes('target');
    const isValidCustomerOrder =
      customerOrder &&
      customerOrder.trim() !== '' &&
      customerOrder.trim().toLowerCase() !== 'n/a';

    if (isTargetCustomer && isValidCustomerOrder) {
      const location_id = extractLocationId();
      if (!location_id) {
        console.error('Location ID could not be extracted from customer name:', customerName);
        setError('Invalid customer name format. Cannot extract location ID.');
        return null;
      }

      // **Send the request payload directly as expected by the API**
      const requestBody = {
        related_tranid: customerOrder.trim(),
        technician_info: selectedTechnician?.employeeName || 'N/A',
        estimated_time_arrival: getEstimatedTimeArrival(),
        location_id: location_id,
      };

      console.log('Sending request to CREATE_VISIT_ENDPOINT:', {
        url: CREATE_VISIT_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: requestBody,
      });

      try {
        const response = await fetch(CREATE_VISIT_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(requestBody),
        });

        console.log('Received response from CREATE_VISIT_ENDPOINT:', {
          status: response.status,
          ok: response.ok,
        });

        const responseText = await response.text();
        console.log('Response body:', responseText);

        if (!response.ok) {
          console.error('Failed to fetch VisitID:', response.status, responseText);
          try {
            const errorData = JSON.parse(responseText);
            setError(`Failed to fetch VisitID: ${errorData.error || 'Unknown error'}`);
          } catch (parseError) {
            setError('Failed to fetch VisitID. Appointment created without VisitID.');
          }
          return null;
        }

        // Directly parse the responseText as JSON
        const data = JSON.parse(responseText);
        console.log('Parsed response data:', data);

        // Use the correct key 'visit_id' from the response
        if (data && typeof data.visit_id === 'number') {
          console.log('Fetched VisitID:', data.visit_id);
          return data.visit_id;
        } else {
          console.error('VisitID not found or invalid in response:', data);
          setError('VisitID not found or invalid in response. Appointment created without VisitID.');
          return null;
        }
      } catch (error) {
        console.error('Error fetching VisitID:', error);
        setError('Failed to fetch VisitID. Appointment created without VisitID.');
        return null;
      }
    }

    return null;
  };

/**
 * Handles the click on the appointment date.
 * Opens the date change modal.
 * @param {Object} appointment - The appointment object.
 */
const handleAppointmentDateClick = (appointment) => {
  setAppointmentToChangeDate(appointment);
  setNewAppointmentDate(appointment.AppointmentDate); // Initialize with current date
  setShowDateChangeModal(true);
};

  /**
   * Creates a new appointment by calling the backend API.
   * If the customer is a "Target" customer, it first creates a visit and includes the VisitID.
   * Otherwise, it creates an appointment without a VisitID.
   */
  const handleCreateAppointment = async () => {
    if (!selectedAppointmentDate) {
      alert('Please select an appointment date.');
      return;
    }

    if (!selectedTechnician) {
      console.error("No technician selected");
      // Handle the case where no technician is selected
      return;
    }

    // Show confetti immediately when the button is clicked
    setShowConfetti(true);

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const description = serviceOrderDetails?.description || serviceOrderDetails?.details || '';

      // Sanitize the customerName and description to prevent SQL injection
      const sanitizeInput = (input) => {
        return input.replace(/'/g, "''"); // Escape single quotes by doubling them
      };

      const sanitizedCustName = sanitizeInput(customerName);
      const sanitizedAppDescription = sanitizeInput(description);

      const isTargetCustomer = customerName.toLowerCase().includes('target');

      let visitID = null;

      if (isTargetCustomer) {
        visitID = await fetchVisitID();
        if (visitID === null) {
          setError('Cannot create appointment without a valid VisitID.');
          setLoading(false);
          return;
        }
      }

      const requestBody = {
        ServiceOrderID: serviceOrderNumber,
        TechnicianEmail: selectedTechnician
          ? selectedTechnician.email
          : 'acumatica@collins-cs.com',
        AppointmentDate: selectedAppointmentDate,
        Tech: techNumber,
        Duration: appointmentDuration,
        BranchLocation: serviceOrderDetails.department || 'N/A',
        CustAddress: serviceOrderDetails.address || 'N/A',
        CustName: sanitizedCustName,
        AppDescription: sanitizedAppDescription,
        ...(isTargetCustomer && { VisitID: visitID }),
      };

      console.log('Sending request to CREATE_APPOINTMENT_ENDPOINT:', {
        url: CREATE_APPOINTMENT_ENDPOINT,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: requestBody,
      });

      const response = await fetch(CREATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(requestBody),
      });

      console.log('Received response from CREATE_APPOINTMENT_ENDPOINT:', {
        status: response.status,
        ok: response.ok,
      });

      const responseText = await response.text();
      console.log('Response body:', responseText);

      if (!response.ok) {
        console.error('Failed to create appointment:', response.status, responseText);
        try {
          const errorData = JSON.parse(responseText);
          setError(`Failed to create appointment: ${errorData.error || 'Unknown error'}`);
        } catch (parseError) {
          setError('Failed to create appointment.');
        }
        throw new Error('Failed to create appointment');
      }

      const data = JSON.parse(responseText);
      console.log('Create Appointment Response:', data);

      let parsedBody;
      try {
        parsedBody = JSON.parse(data.body);
        console.log('Parsed appointment data:', parsedBody);
      } catch (parseError) {
        console.error('Error parsing response body:', parseError);
        throw new Error('Invalid response format from Create Appointment API');
      }

      if (parsedBody && parsedBody.data) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody.data;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName:
              selectedTechnician.email === 'acumatica@collins-cs.com'
                ? 'Unassigned'
                : selectedTechnician.employeeName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber, // Store Tech number in the appointment
            Duration: appointmentDuration, // Store Duration in the appointment
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0, // Set OnHold to 0 (No) by default
          },
        ]);
      } else if (parsedBody && parsedBody.AppointmentNumber) {
        const { AppointmentNumber, TechnicianEmail, Status } = parsedBody;

        setAppointments((prevAppointments) => [
          ...prevAppointments,
          {
            AppointmentNumber: AppointmentNumber,
            TechnicianName:
              selectedTechnician.email === 'acumatica@collins-cs.com'
                ? 'Unassigned'
                : selectedTechnician.employeeName,
            TechnicianEmail: TechnicianEmail,
            Tech: techNumber, // Store Tech number in the appointment
            Duration: appointmentDuration, // Store Duration in the appointment
            VisitID: visitID || 'N/A',
            AppointmentDate: selectedAppointmentDate,
            UpdatedAt: new Date().toISOString(),
            Status: Status || 'Assigned',
            OnHold: 0, // Set OnHold to 0 (No) by default
          },
        ]);
      } else {
        console.error('Missing "data" property in response:', parsedBody);
        throw new Error('Invalid response structure from Create Appointment API');
      }

      handleModalClose();
      setSuccess('Appointment created successfully.');

      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error creating appointment:', error);
      setError(error.message || 'Failed to create appointment');
    } finally {
      setLoading(false);
      setTimeout(() => {
        setShowConfetti(false);
        handleModalClose();
      }, 5000);
    }
  };

  /**
   * Initiates the cancellation process for a selected appointment.
   * @param {Object} appointment - The appointment object to be canceled.
   */
  const handleCancelAppointment = (appointment) => {
    setAppointmentToCancel(appointment);
    setShowCancelModal(true);
  };

  /**
   * Confirms and processes the cancellation of an appointment.
   */
  const confirmCancelAppointment = async () => {
    if (!appointmentToCancel) return;

    try {
      setLoading(true);

      const customerName = serviceOrderDetails?.customerName || '';
      const isTargetCustomer = customerName.toLowerCase().includes('target');
      const visitID = appointmentToCancel.VisitID; // Assuming VisitID is stored in the appointment object

      await cancelAppointment(serviceOrderNumber, appointmentToCancel.AppointmentNumber, visitID, isTargetCustomer);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToCancel.AppointmentNumber
            ? { ...appt, Status: 'Canceled', UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setShowCancelModal(false);
      setAppointmentToCancel(null);
      setSuccess('Appointment canceled successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error canceling appointment:', error);
      setError(error.message || 'Failed to cancel appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the dispatching of an appointment by updating its status to "Dispatched".
   * @param {Object} appointment - The appointment object to be dispatched.
   */
  const handleDispatchAppointment = async (appointment) => {
    try {
      setLoading(true);
      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, 'Dispatched');

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, Status: 'Dispatched', UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setSuccess('Appointment dispatched successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error dispatching appointment:', error);
      setError(error.message || 'Failed to dispatch appointment');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Formats a date string into MM/DD/YYYY format using UTC to prevent time zone issues.
   * @param {string} dateString - The date string to format.
   * @returns {string} - The formatted date string.
   */
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const year = dateObj.getUTCFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const fetchedTechnicians = await fetchAndMatchTechnicians();
        setTechnicians(fetchedTechnicians);
      } catch (error) {
        console.error('Error fetching technicians:', error);
        setError('Failed to load technicians. Please try again later.');
      }
    };

    fetchTechnicians();
  }, []);

  useEffect(() => {
    const filterTechnicians = () => {
      if (!Array.isArray(technicians)) return [];

      return technicians.filter((tech) =>
        Object.values(tech).some((value) =>
          value.toString().toLowerCase().includes(technicianSearchTerm.toLowerCase())
        )
      );
    };

    setFilteredTechnicians(filterTechnicians());
  }, [technicians, technicianSearchTerm]);

  const handleTechnicianSearchChange = (e) => {
    setTechnicianSearchTerm(e.target.value);
  };

  const handleNextStep = () => {
    if (modalStep === 1) {
      setModalStep(2); // Move to the next step regardless of technician selection
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    setModalStep(1); // Reset to first step when modal is closed
    setSelectedTechnician(null);
    setSelectedAppointmentDate(null);
  };

  const getButtonLabel = (status) => {
    switch (status.toLowerCase()) {
      case 'assigned':
        return 'Dispatch';
      case 'dispatched':
        return 'Depart';
      case 'depart':
        return 'Arrive';
      case 'arrive':
        return 'Complete';
      case 'complete':
        return 'Completed';
      case 'canceled':
        return 'Canceled';
      default:
        return '';
    }
  };

  /**
   * Handles the status change of an appointment.
   * Sends an email when an appointment is dispatched or completed.
   * @param {Object} appointment - The appointment object.
   */
  const handleStatusChange = async (appointment) => {
    try {
      setLoading(true);
      let newStatus;
      switch (appointment.Status.toLowerCase()) {
        case 'assigned':
          newStatus = 'Dispatched';
          break;
        case 'dispatched':
          newStatus = 'Depart';
          break;
        case 'depart':
          newStatus = 'Arrive';
          break;
        case 'arrive':
          newStatus = 'Complete';
          break;
        default:
          return;
      }

      await updateAppointmentStatus(serviceOrderNumber, appointment.AppointmentNumber, newStatus);

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, Status: newStatus, UpdatedAt: new Date().toISOString() }
            : appt
        )
      );

      setSuccess(`Appointment status updated to ${newStatus}.`);

      // **Send an email when the appointment is dispatched**
      if (newStatus === 'Dispatched') {
        // Get the concatenated service order number and appointment number
        const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;

        // Technician's email
        const technicianEmail = appointment.TechnicianEmail;

        // Email components
        const customerName = serviceOrderDetails?.customerName || 'N/A';
        const description = serviceOrderDetails?.details || 'N/A';
        const address = serviceOrderDetails?.address || 'N/A'; // Ensure you have 'address' in serviceOrderDetails

        // Define the Reply-To address
        const replyTo = 'dispatchgroup@collins-cs.com';

        // **Define the email subject**
        const emailSubject = `${serviceOrderAppointmentID} has been dispatched to you.`;

        // Include the specified line in the email body
        let emailBody = `${serviceOrderAppointmentID} has been dispatched to you. Please access CONEXCS to view your appointment.\n\nCustomer: ${customerName}\nDescription: ${description}`;

        // Include the address if available
        if (address && address !== 'N/A') {
          emailBody += `\nAddress: ${address}`;
        }

        // Send the email
        await sendEmail({
          toAddress: technicianEmail,
          cc: '',
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // **Send an email when the appointment is completed**
      if (newStatus === 'Complete') {
        // Get the concatenated service order number and appointment number
        const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;

        // Email recipient
        const recipientEmail = 'dispatchgroup@collins-cs.com';

        // Technician's name
        const technicianName =
          appointment.TechnicianName ||
          (selectedTechnician ? selectedTechnician.employeeName : 'Technician');

        // Email components
        const customerName = serviceOrderDetails?.customerName || 'N/A';
        const description = serviceOrderDetails?.details || 'N/A';
        const address = serviceOrderDetails?.address || 'N/A'; // Ensure you have 'address' in serviceOrderDetails

        // Define the Reply-To address (optional)
        const replyTo = 'dispatchgroup@collins-cs.com'; // Replace with appropriate email if needed

        // **Define the email subject with technician's name**
        const emailSubject = `${serviceOrderAppointmentID} has been completed by ${technicianName}.`;

        // **Construct the email body with technician's name**
        let emailBody = `${serviceOrderAppointmentID} has been completed by ${technicianName}.\n\nCustomer: ${customerName}\nDescription: ${description}`;

        // Include the address if available
        if (address && address !== 'N/A') {
          emailBody += `\nAddress: ${address}`;
        }

        // Send the email
        await sendEmail({
          toAddress: recipientEmail,
          cc: '',
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment status:', error);
      setError(error.message || 'Failed to update appointment status');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Function to handle toggling the hold status of an appointment.
   * @param {Object} appointment - The appointment object.
   */
  const handleToggleHold = async (appointment) => {
    if (holdLoading) return; // Prevent multiple clicks

    setHoldLoading(true);
    setHoldError(null);

    const isOnHold = appointment.OnHold === 1;
    const newHoldStatus = isOnHold ? 0 : 1;
    const displayName = user?.display_name || 'Unknown User'; // Use display_name with fallback
    const onHoldNotes = isOnHold ? '' : `${displayName} has placed this appointment on hold.`;

    const payload = {
      ServiceOrderID: appointment.ServiceOrderID || serviceOrderNumber,
      AppointmentNumber: appointment.AppointmentNumber,
      OnHold: newHoldStatus,
      OnHoldNotes: onHoldNotes,
    };

    try {
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_appointment_onhold',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointment.AppointmentNumber
            ? { ...appt, OnHold: newHoldStatus, OnHoldNotes: onHoldNotes }
            : appt
        )
      );

      setSuccess(
        `Appointment #${appointment.AppointmentNumber} is now ${
          newHoldStatus === 1 ? 'On Hold' : 'Off Hold'
        }.`
      );

      // **Send an email when the appointment hold status changes**
      // Get the concatenated service order number and appointment number
      const serviceOrderAppointmentID = `${serviceOrderNumber}-${appointment.AppointmentNumber}`;

      // Common email components
      const emailTo = appointment.TechnicianEmail;
      const customerName = serviceOrderDetails?.customerName || 'N/A';
      const description = serviceOrderDetails?.details || 'N/A';

      // Define the CC and Reply-To addresses
      const cc = 'dispatchgroup@collins-cs.com';
      const replyTo = 'dispatchgroup@collins-cs.com';

      if (newHoldStatus === 1) {
        // **Email when put on hold**
        const emailSubject = `${serviceOrderAppointmentID} has been put on hold.`;
        const emailBody = `Customer: ${customerName}
Description: ${description}

${serviceOrderAppointmentID} has been put on hold with the following note:

${onHoldNotes}`;

        // Send the email
        await sendEmail({
          toAddress: emailTo,
          cc: cc,
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      } else {
        // **Email when taken off hold**
        const emailSubject = `${serviceOrderAppointmentID} has been taken off hold.`;
        const emailBody = `Customer: ${customerName}
Description: ${description}

${serviceOrderAppointmentID} has been taken off hold.`;

        // Send the email
        await sendEmail({
          toAddress: emailTo,
          cc: cc,
          replyTo: replyTo,
          subject: emailSubject,
          body: emailBody,
        });
      }

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating hold status:', error);
      setHoldError('Failed to update hold status. Please try again.');
    } finally {
      setHoldLoading(false);
    }
  };

  // Fetch service order details if not provided via location.state
  useEffect(() => {
    if (!serviceOrderDetails || Object.keys(serviceOrderDetails).length === 0) {
      const getServiceOrderDetails = async () => {
        try {
          const details = await fetchServiceOrderDetails(serviceOrderNumber);
          setServiceOrderDetails(details);
        } catch (error) {
          console.error('Error fetching service order details:', error);
          setError('Failed to load service order details. Please try again later.');
        }
      };

      getServiceOrderDetails();
    }
  }, [serviceOrderNumber, serviceOrderDetails]);

  /**
   * Handles the click on the technician's name.
   * Opens the technician change modal.
   * @param {Object} appointment - The appointment object.
   */
  const handleTechnicianNameClick = (appointment) => {
    setAppointmentToChangeTech(appointment);
    setSelectedTechnician(null); // Reset selected technician
    setShowTechChangeModal(true);
  };

  /**
   * Handles updating the technician for an appointment.
   * Calls the API to update the technician email.
   */
  const handleUpdateTechnician = async () => {
    if (!selectedTechnician || !appointmentToChangeTech) return;

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum = serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentToChangeTech.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        TechnicianEmail: selectedTechnician.email,
      };

      console.log('Updating technician with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToChangeTech.AppointmentNumber
            ? {
                ...appt,
                TechnicianEmail: selectedTechnician.email,
                TechnicianName:
                  selectedTechnician.email === 'acumatica@collins-cs.com'
                    ? 'Unassigned'
                    : selectedTechnician.employeeName,
              }
            : appt
        )
      );

      setSuccess(
        `Technician for Appointment #${appointmentToChangeTech.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowTechChangeModal(false);
      setAppointmentToChangeTech(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating technician:', error);
      setError('Failed to update technician. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch technicians when the modal is opened
  useEffect(() => {
    if (showTechChangeModal || showModal) {
      // Fetch technicians if not already fetched
      if (!technicians || technicians.length === 0) {
        const fetchTechnicians = async () => {
          try {
            const fetchedTechnicians = await fetchAndMatchTechnicians();
            setTechnicians(fetchedTechnicians);
          } catch (error) {
            console.error('Error fetching technicians:', error);
            setError('Failed to load technicians. Please try again later.');
          }
        };
        fetchTechnicians();
      }
    }
  }, [showTechChangeModal, showModal]);

  /**
   * Handles updating the appointment date for an appointment.
   * Calls the API to update the AppointmentDate field.
   */
  const handleUpdateAppointmentDate = async () => {
    if (!newAppointmentDate || !appointmentToChangeDate) return;

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum = serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentToChangeDate.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        AppointmentDate: newAppointmentDate,
      };

      console.log('Updating appointment date with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentToChangeDate.AppointmentNumber
            ? {
                ...appt,
                AppointmentDate: newAppointmentDate,
              }
            : appt
        )
      );

      setSuccess(
        `Appointment date for Appointment #${appointmentToChangeDate.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowDateChangeModal(false);
      setAppointmentToChangeDate(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment date:', error);
      setError('Failed to update appointment date. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Renders a visual representation of the blocks (duration) and makes them clickable.
   * @param {object} appointment - The appointment object.
   * @returns {JSX.Element} - The visual representation of the blocks.
   */
  const renderBlocks = (appointment) => {
    const duration = appointment.Duration || 1;
    const maxBlocks = 4;

    // Styles for the pips
    const pipStyle = {
      fontSize: '24px',
      marginRight: '2px',
      cursor: 'pointer',
    };

    let blocks = [];
    for (let i = 1; i <= maxBlocks; i++) {
      blocks.push(
        <span
          key={i}
          style={{
            ...pipStyle,
            color: i <= duration ? 'red' : 'gray',
          }}
          onClick={() => handlePipClick(appointment)}
        >
          ●
        </span>
      );
    }

    return <div style={{ whiteSpace: 'nowrap' }}>{blocks}</div>;
  };

  /**
   * Handles the click on the pips to change the duration.
   * @param {object} appointment - The appointment object.
   */
  const handlePipClick = (appointment) => {
    setSelectedAppointmentForDuration(appointment);
    setNewDuration(appointment.Duration || 1);
    setShowDurationModal(true);
  };

  /**
   * Handles updating the duration of an appointment.
   */
  const handleUpdateDuration = async () => {
    if (!selectedAppointmentForDuration) {
      return;
    }

    try {
      setLoading(true);

      // Ensure serviceOrderNumber is defined
      const serviceOrderNum =
        serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;

      // Construct the ServiceOrderAppointmentID
      const serviceOrderAppointmentID = `${serviceOrderNum}-${selectedAppointmentForDuration.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        Duration: newDuration,
      };

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to update appointment duration: ${errorText}`);
      }

      // Optionally parse the response if needed
      const responseData = await response.json();
      console.log('Update Duration Response:', responseData);

      // Update the appointments state with the new duration
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointmentForDuration.AppointmentNumber
            ? {
                ...appt,
                Duration: newDuration,
              }
            : appt
        )
      );

      // Close the modal
      setShowDurationModal(false);
      setSelectedAppointmentForDuration(null);

      // Show success message
      setSuccess('Appointment duration updated successfully.');

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating appointment duration:', error);
      setError('Failed to update appointment duration. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles the click on the notes icon.
   * Opens the notes modal with the current dispatch notes.
   * @param {Object} appointment - The appointment object.
   */
  const handleNotesIconClick = (appointment) => {
    setAppointmentForNotes(appointment);
    setDispatchNotes(appointment.DispatchNotes || ''); // Initialize with current notes
    setShowNotesModal(true);
  };

  /**
   * Handles updating the dispatch notes for an appointment.
   * Calls the API to update the DispatchNotes field.
   */
  const handleSaveDispatchNotes = async () => {
    if (appointmentForNotes === null) return;

    try {
      setLoading(true);

      // Construct the ServiceOrderAppointmentID
      const serviceOrderNum =
        serviceOrderDetails.serviceOrderNumber || serviceOrderNumber;
      const serviceOrderAppointmentID = `${serviceOrderNum}-${appointmentForNotes.AppointmentNumber}`;

      const payload = {
        ServiceOrderAppointmentID: serviceOrderAppointmentID,
        DispatchNotes: dispatchNotes,
      };

      console.log('Updating dispatch notes with payload:', payload);

      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // Update the appointments list
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === appointmentForNotes.AppointmentNumber
            ? {
                ...appt,
                DispatchNotes: dispatchNotes,
              }
            : appt
        )
      );

      setSuccess(
        `Dispatch notes for Appointment #${appointmentForNotes.AppointmentNumber} updated successfully.`
      );

      // Close the modal
      setShowNotesModal(false);
      setAppointmentForNotes(null);

      // Clear success message after 5 seconds
      setTimeout(() => setSuccess(null), 5000);
    } catch (error) {
      console.error('Error updating dispatch notes:', error);
      setError('Failed to update dispatch notes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="awf-content-spacing">
      <div className="awf-card">
        <div className="awf-cardHeader">
          <h2 className="awf-cardTitle">
            Appointments for Service Order {serviceOrderDetails.serviceOrderNumber || serviceOrderNumber || 'N/A'}
          </h2>
          <div className="service-order-details" style={{ marginLeft: '0', marginTop: '10px' }}>
            <p style={{ marginBottom: '0' }}>
              <strong>Customer:</strong> {serviceOrderDetails.customerName || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Description:</strong> {serviceOrderDetails.details || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Customer Order:</strong> {serviceOrderDetails.customerOrder || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Department:</strong> {serviceOrderDetails.department || 'N/A'}
            </p>
            <p style={{ marginBottom: '0' }}>
              <strong>Address:</strong> {serviceOrderDetails.address || 'N/A'}
            </p>
          </div>
        </div>
        <nav className="breadcrumb" style={{ marginBottom: '20px', fontSize: '16px' }}>
          <Link to="/ServiceOrderLookup" className="breadcrumb-link" style={{ textDecoration: 'none', color: '#007bff' }}>
            Service Order Lookup
          </Link>
          <span className="breadcrumb-separator" style={{ margin: '0 8px' }}>/</span>
          <span className="breadcrumb-current" style={{ color: '#6c757d' }}>Appointments</span>
        </nav>
        <div className="awf-cardContent">
          {appointmentsLoading && (
            <div className="awf-loading">
              <Spinner animation="border" role="status" size="sm"></Spinner>
              <span> Loading appointments...</span>
            </div>
          )}
          {error && <div className="awf-error">{error}</div>}
          {success && <div className="awf-success">{success}</div>}
          {holdError && <div className="awf-error">{holdError}</div>}
          {!appointmentsLoading && !error && (
            <>
              {appointments.length > 0 ? (
                <table className="awf-table">
                  <thead>
                    <tr>
                      <th style={{ width: '150px' }}>#</th>
                      <th>Technician</th>
                      <th>Tech #</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Hold</th>
                      <th style={{ width: '150px' }}>Blocks</th>
                      <th style={{ width: '220px' }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appointments.map((appointment, index) => (
                      <tr key={index}>
                        <td>{appointment.AppointmentNumber}</td>
                        <td>
                          {['assigned', 'dispatched'].includes(appointment.Status.toLowerCase()) ? (
                            <span
                              onClick={() => handleTechnicianNameClick(appointment)}
                              style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                            >
                              {appointment.TechnicianEmail === 'acumatica@collins-cs.com'
                                ? 'Unassigned'
                                : appointment.TechnicianName || 'N/A'}
                            </span>
                          ) : (
                            appointment.TechnicianEmail === 'acumatica@collins-cs.com'
                              ? 'Unassigned'
                              : appointment.TechnicianName || 'N/A'
                          )}
                        </td>
                        <td>{appointment.Tech}</td>
                        <td>
                          <span
                            onClick={() => handleAppointmentDateClick(appointment)}
                            style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}
                          >
                            {appointment.AppointmentDate
                              ? formatDate(appointment.AppointmentDate)
                              : 'N/A'}
                          </span>
                        </td>
                        <td>{appointment.Status}</td>
                        <td>
                          <span
                            onClick={() => handleToggleHold(appointment)}
                            style={{
                              color: appointment.OnHold === 1 ? 'red' : 'green',
                              cursor: holdLoading ? 'not-allowed' : 'pointer',
                              textDecoration: 'underline',
                            }}
                          >
                            {appointment.OnHold === 1 ? 'Yes' : 'No'}
                          </span>
                        </td>
                        <td>{renderBlocks(appointment)}</td>
                        <td>
                          <button
                            className="awf-button awf-dispatchButton"
                            onClick={() => handleStatusChange(appointment)}
                            disabled={
                              appointment.Status.toLowerCase() === 'complete' ||
                              appointment.Status.toLowerCase() === 'canceled' ||
                              loading
                            }
                            style={{
                              width: '110px',
                              textAlign: 'center',
                              backgroundColor:
                                appointment.Status.toLowerCase() === 'complete' ||
                                appointment.Status.toLowerCase() === 'canceled'
                                  ? '#6c757d'
                                  : '#007bff',
                              cursor:
                                appointment.Status.toLowerCase() === 'complete' ||
                                appointment.Status.toLowerCase() === 'canceled'
                                  ? 'not-allowed'
                                  : 'pointer',
                              marginRight: '8px',
                            }}
                          >
                            {getButtonLabel(appointment.Status)}
                          </button>
                          <button
                            className="awf-button awf-cancelButton"
                            onClick={() => handleCancelAppointment(appointment)}
                            disabled={
                              appointment.Status.toLowerCase() === 'canceled' ||
                              appointment.Status.toLowerCase() === 'complete' ||
                              loading
                            }
                            style={
                              appointment.Status.toLowerCase() === 'canceled' ||
                              appointment.Status.toLowerCase() === 'complete'
                                ? {
                                    backgroundColor: '#6c757d',
                                    cursor: 'not-allowed',
                                  }
                                : {}
                            }
                          >
                            Cancel
                          </button>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip>
                                {appointment.DispatchNotes ? appointment.DispatchNotes : 'Add Dispatch Notes'}
                              </Tooltip>
                            }
                          >
                            <span
                              className="awf-notesIcon"
                              onClick={() => handleNotesIconClick(appointment)}
                              style={{ marginLeft: '8px', cursor: 'pointer' }}
                            >
                              <FaStickyNote
                                color={appointment.DispatchNotes ? '#007bff' : '#6c757d'} // Blue if notes exist, gray otherwise
                                size={20}
                              />
                            </span>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="awf-noResults">No appointments found for this service order.</div>
              )}
              <button
                className="awf-button awf-addButton"
                onClick={handleAddAppointment}
                disabled={loading || holdLoading}
              >
                {loading || holdLoading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Loading...
                  </>
                ) : (
                  'Add Appointment'
                )}
              </button>

              {/* Modal for selecting technician */}
              <Modal
                ref={modalRef} // Attach the ref to the modal
                show={showModal}
                onHide={handleModalClose}
                size="lg"
                aria-labelledby="technician-selection-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="technician-selection-modal">
                    {modalStep === 1 ? 'Select Technician' : 'Select Appointment Date'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ position: 'relative' }}>
                  {showConfetti && (
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none', // Ensure confetti doesn't block interactions
                        overflow: 'hidden', // Ensure confetti is contained
                      }}
                    >
                      <Confetti
                        width={modalDimensions.width}
                        height={modalDimensions.height}
                        numberOfPieces={200} // Adjust the number of pieces as needed
                      />
                    </div>
                  )}
                  {modalStep === 1 ? (
                    <>
                      <input
                        type="text"
                        placeholder="Search technicians..."
                        value={technicianSearchTerm}
                        onChange={handleTechnicianSearchChange}
                        className="awf-searchInput"
                      />
                      {filteredTechnicians.length > 0 ? (
                        <ul className="awf-technician-list">
                          {filteredTechnicians.map((tech) => {
                            // Display 'Unassigned' for Acumatica
                            const displayName =
                              tech.email === 'acumatica@collins-cs.com' ? 'Unassigned' : tech.employeeName;

                            return (
                              <li
                                key={tech.user_id}
                                className={`awf-technician-item ${
                                  selectedTechnician && selectedTechnician.user_id === tech.user_id
                                    ? 'selected'
                                    : ''
                                }`}
                                onClick={() => handleSelectTechnician(tech)}
                              >
                                {displayName}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <div>No technicians available</div>
                      )}
                    </>
                  ) : (
                    <>
                      {/* Appointment Date Selection */}
                      <input
                        type="date"
                        value={selectedAppointmentDate || ''}
                        onChange={(e) => setSelectedAppointmentDate(e.target.value)}
                        className="awf-dateInput"
                      />

                      {/* Duration Selection */}
                      <div style={{ marginTop: '15px' }}>
                        <label htmlFor="appointmentDuration"><strong>Duration (Blocks):</strong></label>
                        <select
                          id="appointmentDuration"
                          value={appointmentDuration}
                          onChange={(e) => setAppointmentDuration(parseInt(e.target.value, 10))}
                          style={{ marginLeft: '10px', padding: '5px' }}
                        >
                          <option value={1}>1 Block</option>
                          <option value={2}>2 Blocks</option>
                          <option value={3}>3 Blocks</option>
                          <option value={4}>4 Blocks</option>
                        </select>
                      </div>
                    </>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    <label htmlFor="techNumber" style={{ marginRight: '5px' }}>Tech #</label>
                    <input
                      id="techNumber"
                      type="number"
                      value={techNumber}
                      onChange={(e) => setTechNumber(parseInt(e.target.value, 10))}
                      className="awf-techInput"
                      style={{ width: '40px', textAlign: 'center' }} // Adjust width for two digits
                    />
                  </div>
                  <Button variant="secondary" onClick={handleModalClose}>
                    Cancel
                  </Button>
                  {modalStep === 1 ? (
                    <Button
                      variant="primary"
                      onClick={handleNextStep}
                      // Enable the Next button even if no technician is selected
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      onClick={handleCreateAppointment}
                      disabled={!selectedAppointmentDate || loading}
                    >
                      {loading ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />{' '}
                          Creating...
                        </>
                      ) : (
                        'Create Appointment'
                      )}
                    </Button>
                  )}
                </Modal.Footer>
              </Modal>

              {/* Cancel Confirmation Modal */}
              <Modal
                show={showCancelModal}
                onHide={() => setShowCancelModal(false)}
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Cancellation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to cancel Appointment #{appointmentToCancel?.AppointmentNumber}?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowCancelModal(false)}>
                    No
                  </Button>
                  <Button
                    variant="danger"
                    onClick={confirmCancelAppointment}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Canceling...
                      </>
                    ) : (
                      'Yes, Cancel Appointment'
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Modal for changing technician */}
              <Modal
                show={showTechChangeModal}
                onHide={() => setShowTechChangeModal(false)}
                size="lg"
                aria-labelledby="technician-change-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="technician-change-modal">
                    Change Technician for Appointment #{appointmentToChangeTech?.AppointmentNumber}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    type="text"
                    placeholder="Search technicians..."
                    value={technicianSearchTerm}
                    onChange={handleTechnicianSearchChange}
                    className="awf-searchInput"
                  />
                  {filteredTechnicians.length > 0 ? (
                    <ul className="awf-technician-list">
                      {filteredTechnicians.map((tech) => {
                        // Display 'Unassigned' for Acumatica
                        const displayName =
                          tech.email === 'acumatica@collins-cs.com' ? 'Unassigned' : tech.employeeName;

                        return (
                          <li
                            key={tech.user_id}
                            className={`awf-technician-item ${
                              selectedTechnician && selectedTechnician.user_id === tech.user_id ? 'selected' : ''
                            }`}
                            onClick={() => handleSelectTechnician(tech)}
                          >
                            {displayName}
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    <div>No technicians available</div>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowTechChangeModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleUpdateTechnician}
                    disabled={!selectedTechnician || loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Updating...
                      </>
                    ) : (
                      'Save Changes'
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Modal for changing appointment date */}
              <Modal
                show={showDateChangeModal}
                onHide={() => setShowDateChangeModal(false)}
                size="md"
                aria-labelledby="appointment-date-change-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="appointment-date-change-modal">
                    Change Appointment Date for Appointment #{appointmentToChangeDate?.AppointmentNumber}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    type="date"
                    value={newAppointmentDate ? new Date(newAppointmentDate).toISOString().split('T')[0] : ''}
                    onChange={(e) => setNewAppointmentDate(e.target.value)}
                    className="awf-dateInput"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDateChangeModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleUpdateAppointmentDate}
                    disabled={!newAppointmentDate || loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Updating...
                      </>
                    ) : (
                      'Save Changes'
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Duration Change Modal */}
              <Modal show={showDurationModal} onHide={() => setShowDurationModal(false)}>
                <Modal.Header closeButton>
                  <Modal.Title>Change Appointment Duration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="formAppointmentDuration">
                      <Form.Label>Select New Duration (Blocks)</Form.Label>
                      <Form.Control
                        as="select"
                        value={newDuration}
                        onChange={(e) => setNewDuration(parseInt(e.target.value, 10))}
                      >
                        <option value={1}>1 Block</option>
                        <option value={2}>2 Blocks</option>
                        <option value={3}>3 Blocks</option>
                        <option value={4}>4 Blocks</option>
                      </Form.Control>
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowDurationModal(false)}>
                    Cancel
                  </Button>
                  <Button variant="primary" onClick={handleUpdateDuration}>
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* Dispatch Notes Modal */}
              <Modal
                show={showNotesModal}
                onHide={() => setShowNotesModal(false)}
                size="md"
                aria-labelledby="dispatch-notes-modal"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title id="dispatch-notes-modal">
                    Dispatch Notes for Appointment #{appointmentForNotes?.AppointmentNumber}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Form.Group controlId="formDispatchNotes">
                      <Form.Label>Notes</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={dispatchNotes}
                        onChange={(e) => setDispatchNotes(e.target.value)}
                        placeholder="Enter dispatch notes here..."
                      />
                    </Form.Group>
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowNotesModal(false)}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    onClick={handleSaveDispatchNotes}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{' '}
                        Saving...
                      </>
                    ) : (
                      'Save Notes'
                    )}
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

