// ManagePOs.jsx
import './css/managepo.css';
import React, { useState, useEffect, useContext } from 'react';
import { Table, FormControl, Button, Spinner, Alert, Modal } from 'react-bootstrap';
import UserContext from './context/UserContext';
import ReactJoyride from 'react-joyride';

const MANAGE_PO_ENDPOINT =
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_manage_po';
const API_KEY = '3XnwNPAWjV5bVyTEZ83u24cj3PRCA1zz44nifSQz';

export default function ManagePOs() {
  const { user } = useContext(UserContext);

  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [filteredPOs, setFilteredPOs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [poLoading, setPOLoading] = useState(false);
  const [poError, setPOError] = useState(null);
  const [editedPOs, setEditedPOs] = useState({});
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [saveSuccess, setSaveSuccess] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'PONbr', direction: 'ascending' });
  const [filters, setFilters] = useState({
    PONbr: '',
    ServiceOrder: '',
    Appointment: '',
    Technician: '',
    Vendor: '',
    PODate: '',
    Notes: '',
    Processed: '',
  });

  // State for modal
  const [showModal, setShowModal] = useState(false);
  const [currentNote, setCurrentNote] = useState('');
  const [currentPONbr, setCurrentPONbr] = useState(null);

  const [joyrideSteps] = useState([
    {
      target: '.search-field',
      content: 'Use this field to search purchase orders.',
      disableBeacon: true,
    },
    {
      target: '.export-button',
      content: 'Click here to export the current data to CSV.',
    },
    {
      target: '.notes-column',
      content:
        'You can adjust the **Notes** and **Status** for multiple purchase orders.',
    },
    {
      target: '.save-changes-button',
      content: 'Save your changes after editing.',
    },
    // Add more steps as needed
  ]);

  const [runTour, setRunTour] = useState(() => {
    const hasCompletedTour = localStorage.getItem('hasCompletedManagePOTour');
    return !hasCompletedTour;
  });

  useEffect(() => {
    fetchPurchaseOrders();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [purchaseOrders, filters]);

  const fetchPurchaseOrders = async () => {
    setPOLoading(true);
    setPOError(null);
    try {
      const response = await fetch(MANAGE_PO_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          PONbr: '00000', // 00000 indicates fetch operation
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      if (data && data.data) {
        setPurchaseOrders(data.data);
        setFilteredPOs(data.data);
      } else {
        setPOError('No data received from server.');
      }
    } catch (error) {
      console.error('Error fetching Purchase Orders:', error);
      setPOError('Failed to load Purchase Orders. Please try again later.');
    } finally {
      setPOLoading(false);
    }
  };

  const applyFilters = () => {
    let filtered = [...purchaseOrders];

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        filtered = filtered.filter((po) =>
          po[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
        );
      }
    });

    setFilteredPOs(filtered);
  };

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleEditPO = (ponbr, field, value) => {
    setEditedPOs((prev) => ({
      ...prev,
      [ponbr]: {
        ...prev[ponbr],
        [field]: value,
      },
    }));
  };

  const handleSaveChanges = async () => {
    setSaving(true);
    setSaveError(null);
    setSaveSuccess(false);

    const POsToUpdate = Object.entries(editedPOs).map(([ponbr, changes]) => {
      const PONbr = parseInt(ponbr, 10);
      const updatedFields = { PONbr };

      if (changes.Notes !== undefined) {
        updatedFields.Notes = changes.Notes;
      }

      if (changes.Processed !== undefined) {
        let processedValue = null;
        if (changes.Processed === '1') processedValue = 1;
        else if (changes.Processed === '2') processedValue = 2;
        else if (changes.Processed === '') processedValue = null;
        updatedFields.Processed = processedValue;
      }

      return updatedFields;
    });

    console.log('Attempting to save changes:', POsToUpdate);

    try {
      const response = await fetch(MANAGE_PO_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          POs: POsToUpdate,
        }),
      });

      console.log('Response status:', response.status);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response text:', errorText);
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      const data = await response.json();
      console.log('Response data:', data);

      if (data && data.message === 'Operation completed successfully') {
        setSaveSuccess(true);
        setEditedPOs({});
        fetchPurchaseOrders(); // Refresh the data to reflect the latest state
      } else {
        console.warn('Unexpected response message:', data.message);
        setSaveError('Failed to update Purchase Orders. Please try again later.');
      }
    } catch (error) {
      console.error('Error saving changes:', error);
      setSaveError('Failed to update Purchase Orders. Please try again later.');
    } finally {
      setSaving(false);
    }
  };

  const handleClearChanges = () => {
    setEditedPOs({});
    setSaveError(null);
    setSaveSuccess(false);
    fetchPurchaseOrders(); // Re-fetch the data to reset the screen
  };

  const sortedPOs = React.useMemo(() => {
    let sortablePOs = [...filteredPOs];
    if (sortConfig !== null) {
      sortablePOs.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortablePOs;
  }, [filteredPOs, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleNoteClick = (ponbr, note) => {
    setCurrentPONbr(ponbr);
    const editedNote = editedPOs[ponbr]?.Notes;
    setCurrentNote(editedNote !== undefined ? editedNote : note || '');
    setShowModal(true);
  };

  const handleModalSave = () => {
    handleEditPO(currentPONbr, 'Notes', currentNote);
    setShowModal(false);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    if (term.trim() === '') {
      applyFilters(); // Apply column filters if search term is empty
    } else {
      const lowerTerm = term.toLowerCase();
      const filtered = purchaseOrders.filter((po) =>
        Object.values(po).some((value) =>
          value && value.toString().toLowerCase().includes(lowerTerm)
        )
      );
      setFilteredPOs(filtered);
    }
  };

  // Function to export data to CSV
  const exportToCSV = () => {
    const headers = [
      'PO',
      'SO',
      'Technician',
      'Vendor',
      'PO Date',
      'Notes',
      'Status',
    ];

    // Map data to CSV format
    const csvRows = [];

    // Add headers
    csvRows.push(headers.join(','));

    // Add data rows
    sortedPOs.forEach((po) => {
      const editedPO = editedPOs[po.PONbr] || {};
      const displayPO = { ...po, ...editedPO };

      const row = [
        displayPO.PONbr,
        `${displayPO.ServiceOrder}-${displayPO.Appointment}`,
        displayPO.Technician,
        displayPO.Vendor,
        new Date(displayPO.PODate).toLocaleDateString('en-US'),
        `"${displayPO.Notes ? displayPO.Notes.replace(/"/g, '""') : ''}"`, // Escape quotes
        displayPO.Processed === null || displayPO.Processed === undefined
          ? 'Unprocessed'
          : displayPO.Processed === 1
          ? 'Processed'
          : 'Canceled',
      ];
      csvRows.push(row.join(','));
    });

    // Create CSV string
    const csvString = csvRows.join('\n');

    // Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    // Create a link to download the Blob
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);

    // Set the file name
    link.setAttribute('download', 'purchase_orders.csv');

    // Append the link to the body
    document.body.appendChild(link);

    // Trigger the download
    link.click();

    // Clean up and remove the link
    document.body.removeChild(link);
  };

  return (
    <div className="container mt-4">
      <ReactJoyride
        steps={joyrideSteps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
        callback={(data) => {
          const { status } = data;
          if (status === 'finished' || status === 'skipped') {
            setRunTour(false);
            localStorage.setItem('hasCompletedManagePOTour', 'true');
          }
        }}
      />

      <h2>Manage Purchase Orders</h2>

      {/* Search Field */}
      <div className="mb-3 search-field">
        <FormControl
          type="text"
          placeholder="Search POs..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>

      {/* Loading and Error */}
      {poLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status" size="sm" /> Loading Purchase Orders...
        </div>
      ) : poError ? (
        <Alert variant="danger">{poError}</Alert>
      ) : (
        <>
          {/* Purchase Orders Table */}
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th onClick={() => requestSort('PONbr')}>PO</th>
                <th onClick={() => requestSort('ServiceOrder')}>SO</th>
                <th onClick={() => requestSort('Technician')}>Tech</th>
                <th onClick={() => requestSort('Vendor')}>Vendor</th>
                <th onClick={() => requestSort('PODate')}>PO Date</th>
                <th
                  onClick={() => requestSort('Notes')}
                  className="notes-column"
                >
                  Notes
                </th>
                <th
                  onClick={() => requestSort('Processed')}
                  className="status-column"
                >
                  Status
                </th>
              </tr>
              <tr>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter PO"
                    value={filters.PONbr}
                    onChange={(e) => handleFilterChange('PONbr', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter SO"
                    value={filters.ServiceOrder}
                    onChange={(e) => handleFilterChange('ServiceOrder', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter Tech"
                    value={filters.Technician}
                    onChange={(e) => handleFilterChange('Technician', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter Vendor"
                    value={filters.Vendor}
                    onChange={(e) => handleFilterChange('Vendor', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter PO Date"
                    value={filters.PODate}
                    onChange={(e) => handleFilterChange('PODate', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter Notes"
                    value={filters.Notes}
                    onChange={(e) => handleFilterChange('Notes', e.target.value)}
                  />
                </th>
                <th>
                  <FormControl
                    type="text"
                    placeholder="Filter Status"
                    value={filters.Processed}
                    onChange={(e) => handleFilterChange('Processed', e.target.value)}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedPOs.map((po) => {
                const editedPO = editedPOs[po.PONbr] || {};
                const displayPO = { ...po, ...editedPO };

                const originalProcessed = po.Processed !== null && po.Processed !== undefined ? po.Processed.toString() : '';
                const currentProcessed = displayPO.Processed !== null && displayPO.Processed !== undefined ? displayPO.Processed.toString() : '';
                const isProcessedEdited = originalProcessed !== currentProcessed;

                const originalNotes = po.Notes !== null && po.Notes !== undefined ? po.Notes.toString() : '';
                const currentNotes = displayPO.Notes !== null && displayPO.Notes !== undefined ? displayPO.Notes.toString() : '';
                const isNotesEdited = originalNotes !== currentNotes;

                return (
                  <tr key={po.PONbr}>
                    <td>{displayPO.PONbr}</td>
                    <td>{`${displayPO.ServiceOrder}-${displayPO.Appointment}`}</td>
                    <td>{displayPO.Technician}</td>
                    <td>{displayPO.Vendor}</td>
                    <td>{new Date(displayPO.PODate).toLocaleDateString('en-US')}</td>
                    <td
                      onClick={() => handleNoteClick(po.PONbr, po.Notes)}
                      style={{ cursor: 'pointer' }}
                      className={isNotesEdited ? 'edited-field' : ''}
                    >
                      {displayPO.Notes ? displayPO.Notes.substring(0, 20) + '...' : 'Click to add notes'}
                    </td>
                    <td>
                      <FormControl
                        as="select"
                        value={displayPO.Processed !== null && displayPO.Processed !== undefined ? displayPO.Processed.toString() : ''}
                        onChange={(e) => handleEditPO(po.PONbr, 'Processed', e.target.value)}
                        className={isProcessedEdited ? 'edited-field' : ''}
                      >
                        <option value="">Unprocessed</option>
                        <option value="1">Processed</option>
                        <option value="2">Canceled</option>
                      </FormControl>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          {/* Buttons Row */}
          <div className="d-flex justify-content-between mb-3">
            <Button
              variant="success"
              onClick={exportToCSV}
              className="export-button"
            >
              Export to CSV
            </Button>
            <div>
              <Button
                variant="secondary"
                onClick={handleClearChanges}
                disabled={saving}
                className="mr-2"
              >
                Clear
              </Button>
              <Button
                variant="primary"
                onClick={handleSaveChanges}
                disabled={saving || Object.keys(editedPOs).length === 0}
                className="save-changes-button"
              >
                {saving ? 'Saving Changes...' : 'Save Changes'}
              </Button>
            </div>
          </div>

          {/* Save Success/Error Messages */}
          {saveSuccess && (
            <Alert variant="success" className="mt-3">
              Purchase Orders updated successfully.
            </Alert>
          )}
          {saveError && (
            <Alert variant="danger" className="mt-3">
              {saveError}
            </Alert>
          )}
        </>
      )}

      {/* Notes Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            as="textarea"
            rows={5}
            value={currentNote}
            onChange={(e) => setCurrentNote(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleModalSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}